import React, { Component } from 'react';
import './NavigationBar.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import LimaLogoImage from './../img/lima-cap-LLC-Logo-small.png'

class NavigationBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isScrolled: false,
      isTopClass: 'navigation-bar',
    };

  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 75;
      if (!isTop) {
        this.setState({
          isTopClass: 'navigation-bar navigation-bar-scroll',
          isScrolled: true
        })
      } else {
        this.setState({
          isTopClass: 'navigation-bar',
          isScrolled: false
        })
      }
    });
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render = () => {
    return (
      <Navbar className={this.state.isTopClass} light expand="md" sticky={'top'}>
        <NavbarBrand href="/" style={{ marginLeft: '30px' }}>
          <img className={'mb-3 mb-lg-0 header-logo-image ' + (!this.state.isScrolled ? 'hidden' : '')} src={LimaLogoImage} alt="Lima Capital Investment and Asset Management through Machine Learning data modeling" />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar style={{ marginRight: '30px' }}>
          <Nav className="ml-auto" navbar>
            <NavItem className='nav-item'>
              <NavLink href="#home">Home</NavLink>
            </NavItem>
            <NavItem className='nav-item'>
              <NavLink href="#approach">Approach</NavLink>
            </NavItem>
            <NavItem className='nav-item'>
              <NavLink href="#factsheets">Funds</NavLink>
            </NavItem>
            <NavItem className='nav-item'>
              <NavLink href="#team">Team</NavLink>
            </NavItem>
            <NavItem className='nav-item'>
              <NavLink href="#signup">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default NavigationBar;