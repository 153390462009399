import React, { useState } from 'react'
import { sendEmail } from '../utils/SendGridEmail'

const ContactForm = () => {

    const [enquiry, setEnquiry] = useState({
        userName: '',
        userEmail: '',
        userMessage: '',
    })

    const handleChange = (event) => {
        setEnquiry({
            ...enquiry,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (enquiry.userName != '' || enquiry.userEmail != '' || enquiry.userMessage != '') {
            sendEmail(
                enquiry.userName,
                enquiry.userEmail,
                enquiry.userMessage
            );
        }
        setEnquiry(
            {
                userName: '',
                userEmail: '',
                userMessage: '',
            }
        );

    }

    return (
        <div className="contactForm">
            <div>
                <form className="feedback-form" onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div class="form-group">

                                    <input
                                        className="text-input"
                                        id="name-entry"
                                        name="userName"
                                        onChange={handleChange}
                                        placeholder="Name"
                                        required
                                        value={enquiry.userName}
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div class="form-group">
                                    <input
                                        className="text-input"
                                        id="email-entry"
                                        name="userEmail"
                                        type="email"
                                        onChange={handleChange}
                                        placeholder="Email"
                                        required
                                        value={enquiry.userEmail}
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3 mb-md-0">
                                <div class="form-group">
                                    <textarea
                                        className="text-input"
                                        id="message-entry"
                                        name="userMessage"
                                        onChange={handleChange}
                                        placeholder="Your message"
                                        required
                                        rows="5"
                                        value={enquiry.userMessage}
                                        class="form-control"
                                    />
                                </div>
                            <input type="submit" value="Submit" id="sendEmail" class="btn lima-button" style={{float: 'right'}} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

}

export default ContactForm;
