import React, { Fragment, Component } from 'react';
import cookie from 'react-cookies';
import './App.css';

import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
import Approach from './components/Approach';
import Funds from './components/Funds';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AccreditedInvestors from './components/AccreditedInvestors';

import CookieBanner from 'react-cookie-banner';
import LimaCookiesDocument from './resources/Lima_Capital_Cookie_Policy.pdf';
import CookieStyles from './utils/CookieStyles.js'

import {ToastsContainer, ToastsStore} from 'react-toasts';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmAccreditedInvestor: false
    };
  }

  componentWillMount() {
    //cookie.remove('confirmAccreditedInvestor'); //usefull for testing
    this.setState({
      confirmAccreditedInvestor: cookie.load('confirmAccreditedInvestor')
    });
  }

  acceptAccreditedInvestor = () => {
    setTimeout(() => {
      this.setState({ confirmAccreditedInvestor: true });
      cookie.save('confirmAccreditedInvestor', true);
    }, 600);
  };

  render = () => {
    const { confirmAccreditedInvestor } = this.state;

    return (
      <div className='App'>
        {!confirmAccreditedInvestor && (
          <AccreditedInvestors
            acceptAccreditedInvestor={this.acceptAccreditedInvestor}
          />
        )}
        <Fragment>
          <CookieBanner
            styles={CookieStyles}
            message="We use cookies to provide you with a better user experience. By browsing Lima Capital LLC you accept our"
            link={<a href={LimaCookiesDocument} download='Lima-Capital-Cookie-Policy'>use of cookies.</a>}
            buttonMessage='Close'
            onAccept={() => { }}
            cookie="user-has-accepted-cookies" />
          <NavigationBar />
          <Home />
          <Approach />
          <Funds />
          <Team />
          <Contact />
          <Footer />
        </Fragment>
        <ToastsContainer store={ToastsStore}/>
      </div>
    );
  };
}

export default App;
