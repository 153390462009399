import React, { Component } from "react";
import ContactForm from "./ContactForm";
import "./Contact.css";

class Contact extends Component {
  render = () => {
    return (
      <div>
        <section id="signup" className="signup-section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8 mx-auto text-center">
                <i className="far fa-paper-plane fa-2x text-white"></i>
                <h2 className="text-white">Reach out to us below</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section bg-black">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mb-3 mb-md-0 mx-auto">
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-map-marked-alt icon-primary mb-2"></i>
                    <h4 className="text-uppercase m-0">Address</h4>
                    <hr className="my-4" />
                    <div className="small text-black-50">
                      24 St. Georges Street, Port-Louis 11324, Mauritius
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-envelope icon-primary mb-2"></i>
                    <h4 className="text-uppercase m-0">Email</h4>
                    <hr className="my-4" />
                    <div className="small text-black-50">
                      <a href="mailto:brendan@lima.capital">
                        brendan@lima.capital
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-mobile-alt icon-primary mb-2"></i>
                    <h4 className="text-uppercase m-0">Phone</h4>
                    <hr className="my-4" />
                    <div className="small text-black-50">
                      <a href="tel:+2302139128">+230 213 9128</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };
}

export default Contact;
