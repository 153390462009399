import React, { Component } from 'react';
import './Footer.css';
import TermsAndConditions from './../resources/Lima_disclaimer.pdf';


class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      copyrightYear: ''
    };

  }

  componentDidMount = () => {
    let currentYear = new Date().getFullYear();
    this.setState({ copyrightYear: currentYear });
  }

  render = () => {
    return (
      <footer className="bg-black small text-center text-white-50">
        <div className="container">
          Copyright &copy; Lima Capital LLC {this.state.copyrightYear}
        </div>
        <a
          className='resource-download-link'
          href={TermsAndConditions}
          download='Lima-Capital-ML-Framework'
        >
          &nbsp;Legal Disclaimer
                </a>
      </footer>
    );
  }
}

export default Footer;
