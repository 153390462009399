import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AccreditedInvestors.scss';

import TermsAndConditions from './../resources/Lima_disclaimer.pdf';

class AccreditedInvestors extends Component {
  render() {
    const { acceptAccreditedInvestor } = this.props;
    return (
      <div className='window-container'>
        <div className='exp'>
          <div className='checkbox'>
            <form>
              <div>
                <input type='checkbox' id='check' name='check' value='' />
                <label htmlFor='check' onClick={acceptAccreditedInvestor}>
                  <span />
                  By proceeding you are agreeing with our
                </label>
                <a
                  className='resource-download-link'
                  href={TermsAndConditions}
                  download='Lima-Capital-ML-Framework'
                >
                  &nbsp;terms and conditions
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AccreditedInvestors.propTypes = {
  acceptAccreditedInvestor: PropTypes.func.isRequired
};

export default AccreditedInvestors;
