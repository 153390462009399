import React, { Component } from "react";
import "./Team.css";
import BashirImage from "./../img/team/bashir.jpg";
import BrendanImage from "./../img/team/brendan.jpg";
import HugoImage from "./../img/team/hugo.jpg";
import HashimImage from "./../img/team/hashim.jpg";

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  render = () => {
    return (
      <section className="bg-light team-section" id="team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Team</h2>
              <hr align="center" width="40rem" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="team-member">
                <div className="centered-team-member">
                  <img
                    className="img-team rounded "
                    src={BrendanImage}
                    alt="Brendan Gallagher Lima Capital COO Investment Modeling"
                  />
                  <h4>Brendan Gallagher</h4>
                  <p className="text-muted">
                    CEO/COO
                    <span className="social-icon">
                      <a
                        href="https://www.linkedin.com/in/brendan-gallagher-ab643220/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in icon-primary"></i>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team-member">
                <div className="centered-team-member">
                  <img
                    className="img-team rounded"
                    src={BashirImage}
                    alt="Mahmood Bashir Nabeebokus Lima Capital Expert in Investment and Data modeling"
                  />
                  <h4>Bashir Nabeebokus</h4>
                  <p className="text-muted">
                    CFO
                    <span className="social-icon">
                      <a
                        href="https://www.linkedin.com/in/mahmood-bashir-nabeebokus-1804334b/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in icon-primary"></i>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team-member rounded">
                <div className="centered-team-member">
                  <img
                    className="img-team rounded"
                    src={HugoImage}
                    alt="Hugo May Lima Capital Artificial Inteligence and Machine Learning Head of Strategy"
                  />
                  <h4>Hugo May</h4>
                  <p className="text-muted">
                    Head of Strategy
                    <span className="social-icon">
                      <a
                        href="https://www.linkedin.com/in/hugo-may-110991a6/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in icon-primary"></i>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="team-member rounded">
                <div className="centered-team-member">
                  <img
                    className="img-team rounded"
                    src={HashimImage}
                    alt="Hashim Joomye Lima Capital Artificial Inteligence Modeling Investor"
                  />
                  <h4>Hashim Joomye</h4>
                  <p className="text-muted">
                    Investments
                    <span className="social-icon">
                      <a
                        href="https://www.linkedin.com/in/hashim-joomye-1287916a/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in icon-primary"></i>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default Team;
