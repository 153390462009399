import React, { Component } from 'react';
import './Home.css';

import LimaLogoImage from './../img/Lima-white-copy.png';
import LimaDoc from './../resources/Lima-Framework.pdf';
import LimaAdvisory from './../resources/Lima_Advisory.pdf';
import LimaSummary from './../resources/Lima_summary.pdf';

import LimaAppImage from './../img/mac2.png';


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => { };

  render = () => {
    return (
      <header className='masthead' id='home'>
        <div className='container d-flex h-100 align-items-center home-content'>
          <div className='row'>
            <div className='col-md-6  col-sm-12  col-xs-12'>
              <div className='mx-auto text-left'>
                <img
                  className='img-fluid mb-3 mb-lg-0 logo-image'
                  src={LimaLogoImage}
                  alt='Lima Capital Investment and Asset Management through Machine Learning data modeling'
                />
                <h2 className='text-white mt-2 mb-3'>
                  Machine Learning Investment Management
            </h2>
                <p className='text-black-100 mt-3  mb-3'>
                  <a
                    className='resource-download-link'
                    href={LimaSummary}
                    download='Lima-Capital-Summary'
                  >
                    <i className="fa fa-download icon-primary"></i>&nbsp;
                    Lima Executive Summary Document
            </a>
                  <br />
                  <a
                    className='resource-download-link'
                    href={LimaAdvisory}
                    download='Lima-Capital-Advisory-Services'
                  >
                    <i className="fa fa-download icon-primary"></i>&nbsp;
                    Lima Advisory Services Document
                </a>
                  <br />
                  <a
                    className='resource-download-link'
                    href={LimaDoc}
                    download='Lima-Capital-ML-Framework'
                  >
                    <i className="fa fa-download icon-primary"></i>&nbsp;
                 Lima Investment Framework Paper
              </a>
                </p>
              </div>
            </div>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div className='mx-auto text-center'>
                <img
                  className='img-fluid mb-3 mb-lg-0 app-image'
                  src={LimaAppImage}
                  alt='Lima Capital Investment and Asset Management through Machine Learning data modeling'
                />
              </div>
              <div className='mx-auto text-center mt-1 mb-3'>
                <form action="https://app.lima.capital">
                  <button className='btn lima-button'>Try the interactive fund tool</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };
}

export default Home;
