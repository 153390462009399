import React, { Component } from 'react';
import './Funds.css';

import Modal from 'react-modal';

import ConservativeIcon from '../icons/ConservativeIcon'
import BalancedIcon from '../icons/BalancedIcon'
import UnconstrainedIcon from '../icons/UnconstrainedIcon'

import ConservativeFactsheetImage from './../img/factsheets/conservativefund.jpg'
import BalancedFactsheetImage from './../img/factsheets/balancedfund.jpg'
import UnconstrainedFactsheetImage from './../img/factsheets/unconstrainedfund.jpg'

import ConservativeFund from './funds/ConservativeFund'
import BalancedFund from './funds/BalancedFund'
import UnconstrainedFund from './funds/UnconstrainedFund'

let customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

if (document.documentElement.clientWidth <= 786) {
    
customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'scroll',
        maxHeight: '76vh'
    }
};
}


Modal.setAppElement('#root')

class Funds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            conservativeModalIsOpen: false,
            balancedModalIsOpen: false,
            unconstrainedModalIsOpen: false
        };

    }

    openConservativeModal = () => {
        this.setState({ conservativeModalIsOpen: true });
    }

    closeConservativeModal = () => {
        this.setState({ conservativeModalIsOpen: false });
    }

    openBalancedModal = () => {
        this.setState({ balancedModalIsOpen: true });
    }

    closeBalancedModal = () => {
        this.setState({ balancedModalIsOpen: false });
    }

    openUnconstrainedModal = () => {
        this.setState({ unconstrainedModalIsOpen: true });
    }

    closeUnconstrainedModal = () => {
        this.setState({ unconstrainedModalIsOpen: false });
    }

    render = () => {
        return (
            <div>
                <section id="factsheets" className="factsheets-section bg-black">
                    <Modal />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2 className="factsheets-heading">Our Funds</h2>
                                <hr align="center" width="40rem" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3 mb-md-0 text-center">
                                <ConservativeIcon />
                                <button onClick={this.openConservativeModal} className='btn lima-button'>
                                    <h5 className="m-0">
                                        Lima Conservative<br /> Fund
                                        &nbsp;
                                    <i className="fa fa-search icon-white"></i>
                                    </h5>
                                </button>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0 text-center">
                                <BalancedIcon />
                                <button onClick={this.openBalancedModal} className='btn lima-button'>
                                    <h5 className="m-0">
                                        Lima Balanced<br /> Fund
                                        &nbsp;
                                    <i className="fa fa-search icon-white"></i>
                                    </h5>
                                </button>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0 text-center">
                                <UnconstrainedIcon />
                                <button
                                    onClick={this.openUnconstrainedModal} className='btn lima-button'
                                >
                                    <h5 className="m-0">
                                        Lima Unconstrained<br /> Fund
                                    &nbsp;
                                    <i className="fa fa-search icon-white"></i>
                                    </h5>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.conservativeModalIsOpen}
                        onRequestClose={this.closeConservativeModal}
                        style={customStyles}                        
                        contentLabel="Lima Conservative Fund"
                    >
                        <ConservativeFund />                       
                    </Modal>
                    <Modal
                        isOpen={this.state.balancedModalIsOpen}
                        onRequestClose={this.closeBalancedModal}
                        style={customStyles}                        
                        contentLabel="Lima Balanced Fund"
                    >
                        <BalancedFund/>
                    </Modal>
                    <Modal
                        isOpen={this.state.unconstrainedModalIsOpen}
                        onRequestClose={this.closeUnconstrainedModal}
                        style={customStyles}                       
                        contentLabel="Lima Unconstrained Fund"
                    >
                      <UnconstrainedFund/>
                    </Modal>
                </section>
            </div>
        );
    };
}

export default Funds;
