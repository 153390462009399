import React, { Component } from "react";
import "./Approach.css";

import ParabolaImage from "./../img/Parabola.jpg";
import AiImage from "./../icons/ai.png";

class Approach extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  render = () => {
    return (
      <section id="approach" className="projects-section bg-light">
        <div className="container">
          <div className="row align-items-center no-gutters mb-4 mb-lg-5">
            <div className="col-lg-5">
              <img
                className="img-fluid mb-3 mb-lg-0"
                src={ParabolaImage}
                alt="Machine Learning and Deep Learning Investment Risk Management Parabola"
              />
            </div>
            <div className="col-lg-7">
              <div className="featured-text text-center text-lg-left">
                <h4>Machine Learning</h4>
                <p className="text-black-50 mb-0">
                  The team behind Lima Capital LLC have successfully implemented
                  Machine Learning techniques across a range of investment
                  offerings. Machine Learning has proven a strong track record
                  of highly effective decision making in the managed risk space.
                  Due to significant technical complexity, the fast pace of
                  technical innovation, as well as industry conservatism,
                  Machine Learning and specifically deep learning is an
                  underexploited approach to dynamically manage risk in
                  investment portfolios. We at Lima Capital LLC believe this
                  investment approach will change the investment management
                  landscape over the long-term.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row align-items-center no-gutters mb-4 mb-lg-5">
            <div className="col-lg-12">
              <div className="featured-text text-center text-lg-left">
                <h4>How Machine Learning is different from Traditional 'Quant'</h4>
                <p className="text-black-50 mb-0">Below we explore some of the salient differences between these two contrasting approaches</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center text-center no-gutters mb-4 mb-lg-5">
            <div className="col-12">
              <table style={{ width: "100%" }} className='differences-table dotted-border'>
                <tr>
                  <th><h4><strong>Machine Learning</strong> Approach</h4></th>
                  <th><h4><strong>Traditional 'Quant'</strong> Approach</h4></th>
                </tr>
                <tr>
                  <td><span className='lima-blue'>1.</span>&nbsp;&nbsp;Test <strong>wide range</strong> of investment models</td>
                  <td><span className='lima-blue'>1.</span>&nbsp;&nbsp;Intuitively test <strong>specific</strong> quant strategies</td>
                </tr>
                <tr>
                  <td><span className='lima-blue'>2.</span>&nbsp;&nbsp;<strong>Monte Carlo simulations</strong> and oversampling</td>
                  <td><span className='lima-blue'>2.</span>&nbsp;&nbsp;Use <strong>single sample</strong> data set</td>
                </tr>
                <tr>
                  <td><span className='lima-blue'>3.</span>&nbsp;&nbsp;Online learning allows models to <strong>evolve</strong></td>
                  <td><span className='lima-blue'>3.</span>&nbsp;&nbsp;Models produced are <strong>static</strong></td>
                </tr>
                <tr>
                  <td><span className='lima-blue'>4.</span>&nbsp;&nbsp;<strong>Rich data sets</strong> can be used for training</td>
                  <td><span className='lima-blue'>4.</span>&nbsp;&nbsp;Incorporates <strong>minimal data</strong></td>
                </tr>
                <tr>
                  <td><span className='lima-blue'>5.</span>&nbsp;&nbsp;<strong>No explicit human biases</strong> on market structure</td>
                  <td><span className='lima-blue'>5.</span>&nbsp;&nbsp;Predisposed to <strong>human modeling error</strong> and bias</td>
                </tr>
              </table>
            </div>
            <div className="col-6">

            </div>
            <div className="col-6">

            </div>
          </div>
        </div>
       */}
      </section>
    );
  };
}

export default Approach;
