import React from 'react'
import './Funds.css'

const ConservativeFund = () => {
    return (
        <div className='container fund-modal'>
            <div className="row">
                <div className='col-md-12 fund-heading'>
                    CAPITAL MARKETS <strong>CONSERVATIVE (CMC)</strong>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 my-3 text-left key-facts">
                    <h4>KEY FACTS</h4>
                    <p>
                        <strong>Fund structure:</strong>
                        <span className='float-right'>Segregated Portfolio Company (SPC)</span>
                    </p>
                    <p>
                        <strong>Domicile:</strong>
                        <span className='float-right'>Cayman Islands</span>
                    </p>
                    <p>
                        <strong>Status:</strong>
                        <span className='float-right'>Open-ended</span>
                    </p>
                    <p>
                        <strong>Base currency:</strong>
                        <span className='float-right'>USD</span>
                    </p>
                    <p>
                        <strong>Subscription/Redemption settlement:</strong>
                        <span className='float-right'>T+4</span>
                    </p>
                    <p>
                        <strong>Notice period:</strong>
                        <span className='float-right'>None</span>
                    </p>
                    <p>
                        <strong>Initial charge:</strong>
                        <span className='float-right'>0%</span>
                    </p>
                    <p>
                        <strong>Annual management charge:</strong>
                        <span className='float-right'>0.25%</span>
                    </p>
                    <p>
                        <strong>Admin fee:</strong>
                        <span className='float-right'>0.2%</span>
                    </p>
                    <p>
                        <strong>Custody fee:</strong>
                        <span className='float-right'>0.07%*</span>
                    </p>
                    <p>
                        <strong>Performance fees:</strong>
                        <span className='float-right'>None</span>
                    </p>
                    <p>
                        <strong>Minimum investment:</strong>
                        <span className='float-right'>US$ 100,000</span>
                    </p>
                    <p>
                        <strong>Dealing frequency:</strong>
                        <span className='float-right'>Daily at NAV (Business Days)</span>
                    </p>
                    <p>
                        <strong>Valuation point:</strong>
                        <span className='float-right'>5pm Bermuda time on each Dealing Day</span>
                    </p>
                    <p>
                        <strong>Benchmark:</strong>
                        <span className='float-right'>Morningstar EAA USD Cautious</span>
                    </p>
                    <p><small>*Additional trading and other out-of-pocket expenses may be applicable</small></p>
                </div>
                <div className="col-md-7 my-3 text-left">
                    <h4>INVESTMENT OBJECTIVE AND INVESTOR SUITABILITY</h4>
                    <p>The Fund is appropriate for investors looking for stable capital growth at moderate levels of
volatility. Investors should be willing to accept a maximum drawdown or capital loss objective
between -10% and -15%.</p>
                    <h4>INVESTMENT APPROACH</h4>
                    <p>The Fund uses a fully automated machine learning (ML) investment framework to predict the
optimal portfolio composition of the Fund on a weekly basis.
The ML investment framework dynamically prescribes market exposure through the application of
proprietary risk management algorithms with the fundamental goal of capital loss minimization,
subject to which return above benchmark will be maximized.
The Fund is comprised of a single US equity-based ETF (Vanguard Total Stock Market) with desired
market exposure achieved by shorting a determined amount of equity index futures contracts,
effectively reducing the net market exposure.</p>
                </div>
            </div>
        </div>
    )
}

export default ConservativeFund
