const CookieStyles = {
    button: {
        border: '1px solid white',
        borderRadius: 4,
        padding: '5px',
        background: 'transparent',
        color: 'white',
        fontSize: '14px',
        fontWeight: 600,
        opacity: 1,
        right: 20,
        marginTop: -19
    },
    message: {
        display: 'block',        
        textAlign: 'center',
        color: 'white'
    },
    link: {
        textDecoration: 'none',
        fontWeight: 'bold'
    }
}

export default CookieStyles