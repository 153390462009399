import { ToastsStore } from "react-toasts";

export const sendEmail = (senderName, senderEmail, senderMessage) => {
  window.emailjs
    .send("default_service", "template_wvc3zLoK", {
      reply_to: senderEmail,
      from_name: senderName,
      to_name: "brendan@lima.capital",
      message_html:
        "<h1>New enquiry from https://lima.capital</h1><p>" +
        senderMessage +
        "</p>" +
        "<p> From: " +
        senderName +
        "</p>" +
        "<p>" +
        senderEmail +
        "</p>",
    })
    .then((res) => {
      console.log("email sent", res);
      ToastsStore.success("Email Sent!");
    })

    .catch((err) => {
      ToastsStore.error(
        "Something went wrong, please email us on brendan@lima.capital"
      );
      console.error("Failed to send feedback. Error: ", err);
    });
};
